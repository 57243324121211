<template>
    <div class="wrap" style="padding-bottom: 100px;">
        <Aside v-if="asideStatus" @closeAside="asideStatus = false" />
        <header class="header mo_header">
            <div class="header_box">
                <div class="logo">
                    <a @click="linkPlayer()">
                        <img src="/media/images/icon_back_24.svg">
                        <h2 style="margin-bottom:0px;">고객센터</h2>
                    </a>
                </div>
                <div class="right_menu">
                    <div class="alarm_box">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                             @click="mobileAlarm">
                            <path
                                d="M9.02759 19.7773C9.02759 20.5181 9.46002 21.9996 11.1897 21.9996C12.9195 21.9996 13.3519 20.5181 13.3519 19.7773"
                                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M2.00024 18.666H20.3786" stroke="white" stroke-width="2" stroke-linecap="round"
                                  stroke-linejoin="round" />
                            <path
                                d="M3.62183 18.1113V10.3335C3.62183 7.92613 5.13534 3.11133 11.1894 3.11133C17.2434 3.11133 18.7569 8.2965 18.7569 10.8891V18.1113"
                                stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <ellipse v-if="$store.state.noticeCount !== 0" cx="17.6756" cy="6.44444" rx="4.32431" ry="4.44444"
                                     fill="#FF1150" />
                        </svg>
                    </div>
                    <div class="toggle">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                             @click.once="showAside">
                            <g clip-path="url(#clip0_2231_20993)">
                                <path d="M24 20L6 20L6 18L24 18L24 20ZM24 13L12 13L12 11L24 11L24 13ZM24 6L6 6L6 4L24 4L24 6Z"
                                      fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_2231_20993">
                                    <rect width="24" height="24" fill="white" transform="translate(24 24) rotate(180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="one_depth">
                <router-link :to="{
                    name: 'FAQ',
                }" class="one_depth_btn" :class="{ on: this.$route.path === '/cs/faq' }" @click.native="addClass('1')">
                    FAQ
                </router-link>
                <router-link :to="{
                    name: 'Question',
                }" class="one_depth_btn" :class="{ on: this.$route.path === '/cs/question' }" @click.native="addClass('2')">
                    1:1문의
                </router-link>
            </div>
        </header>
        <section class="main cs">
            <div class="main_box">
                <h1>고객센터</h1>
                <div class="cs_main">
                    <div class="side_depth_box">
                        <div class="one_depth">
                            <router-link :to="{
                                name: 'FAQ',
                            }" class="one_depth_btn" :class="{ on: this.$route.path === '/cs/faq' }" @click.native="addClass('1')">
                                FAQ
                            </router-link>
                            <router-link :to="{
                                             name: 'Question',
                                         }" class="one_depth_btn" :class="{ on: this.$route.path === '/cs/question' }"
                                         @click.native="addClass('2')">
                                1:1문의
                            </router-link>
                        </div>
                    </div>
                    <router-view />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api";
import Aside from "@/layout/aside/Aside.vue";
import VueCookies from "vue-cookies";
import router from "@/router";

export default defineComponent({
  data () {
    return {
      asideStatus: false,
      category1: true,
      category2: false
    };
  },
  components: {
    Aside
  },
  methods: {
    linkPlayer () {
      var spaceId = VueCookies.get("spaceId");
      if (spaceId != null) {
        router.push({ name: "Player" });
      } else {
        router.push({ name: "Main" });
      }
    },
    clickPre () {
      history.back();
    },
    showAside () {
      this.asideStatus = true;
    },
    mobileAlarm () {
      router.push({ name: "Notice0" });
    },
    addClass (index) {
      if (index === '1') {
        this.category1 = true;
        this.category2 = false;
      } else {
        this.category1 = false;
        this.category2 = true;
      }
    }
  }
});
</script>

<style></style>
<style src="@/assets/css/font.css"></style>
